import { firstPermittedRoute, USER_ROLE } from "@/constants";

const getFirstPermittedRoute = (roles: string[], preference?: string) => {
  if (roles.includes(USER_ROLE.RECRUITER)) {
    return firstPermittedRoute[USER_ROLE.RECRUITER];
  }

  if (preference) {
    return firstPermittedRoute[preference];
  }

  if (
    roles.includes(USER_ROLE.CANDIDATE) &&
    roles.includes(USER_ROLE.INTERVIEWER)
  ) {
    return firstPermittedRoute[USER_ROLE.INTERVIEWER];
  }

  const role = roles[0];
  const route = firstPermittedRoute[role];
  if (!route) return "";
  else return route;
};
export default getFirstPermittedRoute;
