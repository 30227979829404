import { useEffect, useRef } from "react";

const useFocusInputField = () => {
  const focusInputOnLoadRef = useRef<
    null | HTMLInputElement | HTMLTextAreaElement
  >(null);

  useEffect(() => {
    if (focusInputOnLoadRef.current) focusInputOnLoadRef.current.focus();
  }, []);

  return focusInputOnLoadRef;
};

export default useFocusInputField;
