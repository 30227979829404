import Image from "next/image";
import Link from "next/link";

import { RhScrollbar } from "@rhythm-ui/react";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";

import {
  clientLogoCollection,
  vectorSquares,
  zelevateWordLogoBlackText,
  zelevateWordLogoWhiteText,
} from "@/constants/media";

const EntryLayout = ({
  formPanel,
  greetingTitle,
  greetingSubTitle,
}: {
  formPanel: ReactNode;
  greetingTitle?: string;
  greetingSubTitle?: string;
}) => {
  return (
    <div className="h-screen lg:grid lg:grid-rows-1 lg:grid-cols-2">
      <div className="flex justify-center order-2">
        <RhScrollbar className=" !px-0 w-full h-full mx-tcontainer-sm md:mx-tcontainer pb-[20px] mt-[20px] md:mt-0 md:py-[80px] overflow-y-auto lg:pt-[140px] pr-tsm  flex flex-wrap ">
          <div className="w-full">
            <div className="block lg:hidden mb-tsm">
              <Image
                src={zelevateWordLogoBlackText}
                height="32px"
                width="146px"
              ></Image>
            </div>

            {formPanel}
          </div>
        </RhScrollbar>
      </div>

      {/* left panel */}
      <div className="bg-[#1B2434] relative overflow-clip order-1">
        <div className="absolute overflow-hidden -top-1">
          <Image
            src={vectorSquares}
            layout="fixed"
            width={"2233"}
            height={"374"}
          ></Image>
        </div>
        <div className="absolute -top-10 -left-10 w-48 h-48 rounded-full footer-gradient-1" />
        <div className="absolute -bottom-10 -left-10 w-48 h-48 rounded-full footer-gradient-1" />

        <div className="pt-t4xl px-tsm md:px-txl pb-tm3 relative z-1 flex flex-col justify-between h-full">
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "just",
              delay: 0.7,
            }}
          >
            <Link href={"/"} passHref>
              <Image
                src={zelevateWordLogoWhiteText}
                width="183px"
                height="40px"
                alt="zelevate"
                className="m-0 p-0 mb-tm2 cursor-pointer"
              />
            </Link>
            <h1 className="text-white mb-tm2 ">{greetingTitle}</h1>
            <p className=" text-white">{greetingSubTitle}</p>
          </motion.div>
          <div className="mb-[157px] lg:mb-0" />
          <div>
            <p className="mt-auto text-white mb-tm2">
              Trusted by Leading Companies
            </p>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                type: "just",
                delay: 0.7,
              }}
            >
              <img className="max-h-[180px]" src={clientLogoCollection} />
            </motion.div>
          </div>
        </div>
      </div>
      {/* left panel end */}
    </div>
  );
};

export default EntryLayout;
