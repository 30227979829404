// import { RhGrid, RhIcon } from "@rhythm-ui/react";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";

import {
  RhButton,
  RhIcon,
  RhInputFormik,
  RhInputGroup,
} from "@rhythm-ui/react";
import { Form, Formik } from "formik";
// import type { NextPage } from "next";
import React, { useState } from "react";
import * as Yup from "yup";

import api from "@/services/api";

import { COOKIE } from "@/constants";
import mixPanelEventName from "@/constants/mixPanelEventName";

import useAuth from "@/hooks/useAuth";

import AnalyticsLog from "@/helpers/AnalyticsLog";
import getDomain from "@/helpers/getDomain";
import showToastMessages from "@/helpers/showToastMessages";

// type of login data
interface ClientLoginDataType {
  email: string;
  password: string;
}
// validation schema for formik
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Business email is required."),
  password: Yup.string()
    .min(6, "Password must be minimum of six characters.")
    .required("Password is required."),
});
const ClientLogin = () => {
  const router = useRouter();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setUserAuth } = useAuth();
  // when Sign in  is clicked
  const onLoginHandler = async (data: ClientLoginDataType) => {
    try {
      setIsLoading(true);
      const res = await api.auth.login(data);
      if (res?.status == 200) {
        const domain = getDomain();

        setCookie(COOKIE.ZELACCESS, res.data.token.access, {
          domain,
          path: "/",
          sameSite: "strict",
        });
        setCookie(COOKIE.ZELREFRESH, res.data.token.refresh, {
          domain,
          path: "/",
          sameSite: "strict",
        });
        setCookie(COOKIE.ROLES, res.data.token.role.join("|"), {
          domain,
          path: "/",
          sameSite: "strict",
        });
        setUserAuth();

        // after login success
        router.push("/jobs");
        AnalyticsLog.track(mixPanelEventName.LOGIN);
      }

      //setting cookies
    } catch (err) {
      setIsLoading(false);
      showToastMessages(err);
    }
  };
  return (
    <>
      {/* formik */}
      <div className="flex gap-tsm flex-col">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={onLoginHandler}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form className="flex flex-col [&>label]:mt-tsm">
              <RhInputFormik
                disabled={isLoading}
                label="Business email"
                className="w-full"
                name="email"
              />

              <RhInputGroup className="w-full">
                <RhInputFormik
                  disabled={isLoading}
                  className="w-full"
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  label="Password"
                />
                {
                  <>
                    {values.password.length > 1 && (
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setIsPasswordVisible(!isPasswordVisible);
                        }}
                      >
                        {isPasswordVisible ? (
                          <RhIcon icon="tabler:eye" />
                        ) : (
                          <RhIcon icon="tabler:eye-off" />
                        )}
                      </span>
                    )}
                  </>
                }
              </RhInputGroup>

              <div className="flex flex-col mt-tsm  gap-tsm w-full">
                <RhButton
                  className="w-full"
                  disabled={isLoading}
                  type="submit"
                  size="xl"
                  data-testid="client-login-btn"
                >
                  <>
                    {" "}
                    {isLoading ? (
                      <div className="flex items-end">
                        {" "}
                        <span>Logging in </span>{" "}
                        <RhIcon
                          className="text-sm"
                          icon={"eos-icons:three-dots-loading"}
                        ></RhIcon>
                      </div>
                    ) : (
                      "Log in "
                    )}
                  </>
                </RhButton>
              </div>
            </Form>
          )}
        </Formik>
        <div className="flex justify-between flex-col">
          <p className="text-center mt-t2xl">
            Want to hire Pre-Interviewed Candidates from us?
          </p>
          <RhButton
            layout="link"
            className="p-txs mx-auto"
            onClick={() => router.push("/signup/employer")}
          >
            Start Hiring
          </RhButton>
        </div>
      </div>
    </>
  );
};

export default ClientLogin;
